import { admin2 } from './instance'

// GET admin.salesPos.getMemberDetail
export const GetSalesPosMemberDetail = async ({ shopId, memberId, date }) => {
  return await admin2({
    url: `/${shopId}/salesPos/getMemberDetail`,
    method: 'GET',
    params: {
      memberId,
      date,
    },
  })
}

// GET admin.salesPos.searchMember
export const GetSalesPosSearchMember = async ({ shopId, search, memberId }) => {
  return await admin2({
    url: `/${shopId}/salesPos/searchMember`,
    method: 'GET',
    params: { search, memberId },
  })
}

// GET admin.salesPos.creationConfig
export const GetSalesPosCreationConfig = async ({ shopId }) => {
  return await admin2({
    url: `/${shopId}/salesPos/creationConfig`,
    method: 'GET',
  })
}

// GET admin.salesPos.qrCodeCheckMember
export const GetSalesPosQrCodeCheckMember = async ({ shopId, memberId }) => {
  return await admin2({
    url: `/${shopId}/salesPos/qrCodeCheckMember`,
    method: 'GET',
    params: { memberId },
  })
}

// GET admin.salesPos.getTodayMemberAppointmentOrders
export const GetSalesPosTodayMemberAppointmentOrders = async ({ shopId, memberId }) => {
  return await admin2({
    url: `/${shopId}/salesPos/getTodayMemberAppointmentOrders`,
    method: 'GET',
    params: { memberId },
  })
}

// POST admin.salesPos.preCreateReward
export const SalesPosPreCreateReward = async ({
  shopId, memberId, salesRecordItems,
  salesRecordDiscounts,
  setSalesCashbackAmount,
}) => {
  return await admin2({
    url: `/${shopId}/salesPos/preCreateReward`,
    method: 'POST',
    data: {
      memberId,
      salesRecordItems,
      salesRecordDiscounts,
      setSalesCashbackAmount,
    },
  })
}

// POST admin.salesPos.createSalesRecord
export const SalesPosCreateSalesRecord = async ({
  shopId,
  customer,
  salesRecordItems,
  salesRecordDiscounts,
  salesRecordPayments,
  confirmInfo,
  settings,
}) => {
  return await admin2({
    url: `/${shopId}/salesPos/createSalesRecord`,
    method: 'POST',
    data: {
      customer,
      salesRecordItems,
      salesRecordDiscounts,
      salesRecordPayments,
      confirmInfo,
      settings,
      originDevice: 'dashboard',
    },
  })
}
